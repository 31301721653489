import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import JfmTitle from "../../components/titles/JfmTitle";

const CausesPage = () => {
    return (
        <Layout pageTitle="JCI INdia Foundation | JFM">
            <NavOne />
            <PageHeader title="JFM" />
            <JfmTitle />
            <Footer />
        </Layout>
    );
};

export default CausesPage;
